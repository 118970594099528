<template>
    <v-row class="mt-10">
        <v-col>
            <div class="d-flex flex-column align-center">
                <h1>{{ $t(`project.selectProjectType.heading`) }}</h1>
                <div class="action-section">
                    <v-btn
                        v-for="action in getAction"
                        :key="action.id"
                        depressed
                        block
                        x-large
                        color="primary"
                        width="380"
                        class="my-4"
                        @click="create(action)"
                    >
                        {{ $t(`project.selectProjectType.${action.label}`) }}
                    </v-btn>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "SelectProjectType",

    data() {
        return {
            userActions: [
                {
                    id: 1,
                    type: "garden",
                    label: "btnSeed",
                },
                {
                    id: 2,
                    type: "constructions",
                    label: "btnBuild",
                },
            ],
            adminActions: [
                {
                    id: 1,
                    type: "garden",
                    label: "btnSeed",
                },
                {
                    id: 2,
                    type: "constructions",
                    label: "btnBuild",
                },
                {
                    id: 3,
                    type: "recipe",
                    label: "btnCreateRecipe",
                },
                {
                    id: 4,
                    type: "library",
                    label: "btnCreateLibrary",
                },
                {
                    id: 5,
                    type: "advice",
                    label: "btnCreateAdvice",
                },
            ],
            chefActions: [
                {
                    id: 3,
                    type: "recipe",
                    label: "btnCreateRecipe",
                },
                {
                    id: 5,
                    type: "advice",
                    label: "btnCreateAdvice",
                },
            ],
        };
    },

    computed: {
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),
        getAction() {
            if (this.user_role == "user") {
                return this.userActions;
            }
            if (this.user_role == "chef") {
                return this.chefActions;
            }
            return this.adminActions;
        },
    },

    methods: {
        create(action) {
            this.$router.push({
                name: "ProjectBuilder",
                params: { type: action.id },
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
